import { http } from "../http";

export const getAllServiceBookings = async ({ queryKey }: any) => {
  return await http.get("booking", {
    params: {
      ...[queryKey[1]],
    },
  });
};

export const getBookingById = async ({ queryKey }: any) => {
  return await http.get(`booking/${queryKey[1]}`);
};

export const getbookingFeed = async ({ queryKey }: any) => {
  return await http.get("booking-feed", {
    params: {
      ...queryKey[1],
    },
  });
};

export const assignVolunteer = async ({ id, data }: any) => {
  return await http.patch(`booking/${id}/assign-volunteer`, data);
};

export const createVolunteer = async (data: any) => {
  return await http.post("/volunteer", data);
};

export const getVolunteers = async ({ queryKey }: any) => {
  return await http.get("/volunteer", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getVolunteerById = async ({ queryKey }: any) => {
  return await http.get(`/volunteer/${queryKey[1]}`);
};

export const updateVolunteerById = async ({ id, data }: any) => {
  return await http.patch(`/volunteer/${id}`, data);
};

export const updateVolunteerStatus = async ({ id, data }: any) => {
  return await http.patch(`/volunteer/update/status/${id}`, data);
};

export const updateVolunteerServices = async ({ id, data }: any) => {
  return await http.patch(`/volunteer/update/services/${id}`, data);
};

export const updateVolunteerLocation = async ({ id, data }: any) => {
  return await http.patch(`/volunteer/update/locations/${id}`, data);
};

export const editLocation = async ({ volunteerId, locationId, data }: any) => {
  return await http.patch(
    `volunteer/${volunteerId}/locations/${locationId}`,
    data
  );
};

export const deleteLocation = async ({ volunteerId, locationId }: any) => {
  return await http.delete(`volunteer/${volunteerId}/locations/${locationId}`);
};

export const updateVolunteerKyc = async ({ id, data }: any) => {
  return await http.patch(`/volunteer/update/kyc/${id}`, data);
};

export const updateVolunteerDocumenation = async ({ id, data }: any) => {
  return await http.patch(`/volunteer/update/documentation/${id}`, data);
};

export const getFeed = async () => {
  return await http.get("/feed");
};

export const postFeed = async (data: any) => {
  return await http.post("/feed", data);
};

export const postServiceCategory = async (data: any) => {
  return await http.post("/service-category", data);
};

export const getServiceCategory = async () => {
  return await http.get("/service-category");
};

export const getServiceCategoryById = async ({ queryKey }: any) => {
  return await http.get(`/service-category/${queryKey[1]}`);
};

export const updateServiceCategory = async ({ id, data }: any) => {
  return await http.patch(`/service-category/${id}`, data);
};

export const postServiceType = async (data: any) => {
  return await http.post("/service-type", data);
};

export const updateServiceType = async ({ id, data }: any) => {
  return await http.patch(`/service-type/${id}`, data);
};

export const getService = async ({ queryKey }: any) => {
  return await http.get("/service", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getServiceById = async ({ queryKey }: any) => {
  return await http.get(`/service/${queryKey[1]}`);
};

export const postService = async (data: any) => {
  return await http.post("/service", data);
};

export const updateService = async ({ id, data }: any) => {
  return await http.patch(`/service/${id}`, data);
};

export const sortServices = async (body: any) => {
  return http.patch(`service/sort-order`, body);
};

export const deleteService = async (id: any) => {
  return await http.delete(`/service/${id}`);
};

export const postSubService = async ({ id, data }: any) => {
  return await http.patch(`/service/types/${id}`, data);
};

export const getLockers = async ({ queryKey }: any) => {
  return await http.get("/locker", {
    params: {
      ...queryKey[1],
    },
  });
};

export const createLocker = async (data: any) => {
  return await http.post("/locker", data);
};

export const updateLocker = async ({ id, data }: any) => {
  return await http.patch(`/locker/${id}`, data);
};

export const deleteLocker = async (id: any) => {
  return await http.delete(`/locker/${id}`);
};

export const getEmergencyAlert = async ({ queryKey }: any) => {
  return await http.get(`/emergency-alerts`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const updateRole = ({ roleId, data }: any) => {
  return http.patch(`/volunteer/update/role/${roleId}`, data);
};

export const getNearestVolunteer = ({ queryKey }: any) => {
  return http.get(`/booking/nearest-users/${queryKey[1]}`);
};
